'use client';

import { CacheProvider } from '@emotion/react';
import {
  MantineProvider,
  MantineThemeOverride,
  useEmotionCache,
} from '@mantine/core';
import { useServerInsertedHTML } from 'next/navigation';
import { ReactNode } from 'react';

const theme: MantineThemeOverride = {
  colorScheme: 'light',
  defaultRadius: 'sm',
  fontSizes: {
    xs: '0.5rem',
    sm: '0.6rem',
    md: '0.75rem',
  },
  components: {
    Button: {
      defaultProps: {
        size: 'sm',
        variant: 'light',
        color: 'blue',
      },
    },
    TextInput: {
      defaultProps: {
        size: 'md',
      },
    },
    Select: {
      defaultProps: {
        size: 'md',
      },
    },
    Flex: {
      defaultProps: {
        gap: 'md',
      },
    },
    Avatar: {
      defaultProps: {
        radius: 'xl',
      },
    },
  },
};

export default function RootStyleRegistry({
  children,
}: {
  children: ReactNode;
}) {
  const cache = useEmotionCache();
  cache.compat = true;

  useServerInsertedHTML(() => (
    <style
      data-emotion={`${cache.key} ${Object.keys(cache.inserted).join(' ')}`}
      dangerouslySetInnerHTML={{
        __html: Object.values(cache.inserted).join(' '),
      }}
    />
  ));

  return (
    <CacheProvider value={cache}>
      <MantineProvider theme={theme}>{children}</MantineProvider>
    </CacheProvider>
  );
}
