'use client';
import RootStyleRegistry from './emotion';
import {ReactNode} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import {config} from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

export default function RootLayout({ children }: { children: ReactNode }) {
  return (
    <html lang='en-US'>
    <head>
      <title>Meet PAT</title>
    </head>
    <body>
    <RootStyleRegistry>
      {children}
    </RootStyleRegistry>
    </body>
    </html>
  );
}
